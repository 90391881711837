import { addDays } from 'date-fns';
import {
    ChangeEvent, FC, useCallback, useContext, useMemo, useState
} from 'react';
import { useQueryClient } from 'react-query';
import {
    ErrorModal, FormattedText, Typography
} from 'src/modules/core/components';
import { PaginationContext } from 'src/modules/core/context';
import { EventCard } from 'src/modules/event/components';
import { eventDetailsParams } from 'src/modules/event/constants/event-params';
import {
    GET_EVENT_BY_ID_CACHE_KEY
} from 'src/modules/event/hooks/use-event-by-id';
import {
    generateTimingWarningMessage, getExpiredReminderOffsets, groupEventsByDate,
    validateEvent
} from 'src/modules/event/utils/helpers';
import { useProfile } from 'src/modules/user/hooks/use-profile';
import { ApiService } from 'src/services/api.service';
import classes from './upcoming-meetings-list.module.scss';

import type { EventData, EventDetails, ExtendEventPreview } from 'src/modules/event/types/event.types'
import type { ErrorMessage } from 'src/modules/core/types/error.types'
import type { UpcomingMeetingsListProps } from './upcoming-meetings-list.interface'
const numberPerPage = 5
const today = new Date()

export const UpcomingMeetingsList: FC<UpcomingMeetingsListProps> = ({
  isLoading = false,
  events,
  onCardClick,
  onSwitchChange,
}) => {
  // context
  const { currentPage } = useContext(PaginationContext)
  // state
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null)
  const [loadingEventId, setLoadingEventId] = useState<string>(null)
  // hooks
  const profile = useProfile()
  const queryClient = useQueryClient()

  const filteredEvents = useMemo(() => {
    return groupEventsByDate(
      events,
      addDays(today, numberPerPage * (currentPage - 1)),
      addDays(today, numberPerPage * (currentPage - 1) + numberPerPage),
    )
  }, [events, currentPage])

  const handleSwitchChange = useCallback(
    (eventPreview: ExtendEventPreview) => async (e: ChangeEvent<HTMLInputElement>) => {
      const active = e.target.checked
      const id = eventPreview.parentRecurringId ?? eventPreview.id

      setLoadingEventId(id)

      const eventDetails = await queryClient.fetchQuery<EventDetails>(
        [GET_EVENT_BY_ID_CACHE_KEY, id, eventDetailsParams],
        () => ApiService.Events.getEventById(id, eventDetailsParams),
        { staleTime: 300000 },
      )

      setLoadingEventId(null)

      const event: EventData = { ...eventDetails, ...eventPreview }
      const { isNumberError, isMessageError, isTimingError } = validateEvent(event, profile.data)
      const eventDate = new Date(parseInt(event.event_date, 10))

      if (active) {
        const isPastEvent = new Date() > eventDate
        const timingValues =
          event.default_time_before && !event.active
            ? profile.data?.default_time_before
            : (event?.reminders?.data?.length > 0 && event.reminders.data.map((t) => t.time_before)) || []
        const withinTimingValues = getExpiredReminderOffsets(eventDate, timingValues)

        if (isPastEvent || withinTimingValues.length) {
          return setErrorMessage({
            title: `Couldn't create SMS reminder`,
            description: generateTimingWarningMessage(withinTimingValues),
          })
        }
      }

      if (isNumberError || isMessageError || isTimingError) {
        return setErrorMessage({
          title: 'Couldn’t create SMS reminder',
          description: `There must be phone number, message and timing associated with the reminder.`,
        })
      }

      onSwitchChange && onSwitchChange({ ...event, active })
    },
    [onSwitchChange, profile.data, queryClient],
  )

  return (
    <div className={classes.root}>
      {filteredEvents.map((dayEvents) => (
        <div className={classes.reminders} key={dayEvents.date}>
          <Typography className={classes.dayTitle} variant="body">
            {dayEvents.date}
          </Typography>
          <div>
            {dayEvents.events.map((event) => (
              <div
                key={`${event.id}-${event.event_date}`}
                className={classes.reminder}
                onClick={() => onCardClick && onCardClick(event)}
              >
                <EventCard
                  variant="current"
                  event={event}
                  showSwitch
                  onSwitchChange={handleSwitchChange(event)}
                  isLoading={loadingEventId === event.id}
                />
              </div>
            ))}
            {dayEvents.events.length === 0 && !isLoading && (
              <Typography variant="body" className={classes.textEmpty}>
                <FormattedText id="reminder.text.empty" />
              </Typography>
            )}
          </div>
        </div>
      ))}
      {errorMessage && (
        <ErrorModal
          title={
            <Typography variant="body" className={classes.errorTitle}>
              {errorMessage.title}
            </Typography>
          }
          description={
            <Typography variant="span" className={classes.errorDescription}>
              {errorMessage.description}
            </Typography>
          }
          onClose={() => setErrorMessage(null)}
        />
      )}
    </div>
  )
}
