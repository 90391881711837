import classNames from 'classnames';
import { forwardRef, memo } from 'react';
import { FormattedText, Typography } from 'src/modules/core/components';
import { maxMessageLength } from 'src/modules/event/constants/event.constants';
import {
    ConsumedReminderSegments, CreateReminderHelper
} from 'src/modules/reminder/components';
import uniqid from 'uniqid';
import { Box } from '@material-ui/core';
import classes from './message-input.module.scss';

import type { MessageInputProps } from './message-input.interface'
export const MessageInput = memo(
  forwardRef<HTMLInputElement & HTMLTextAreaElement, MessageInputProps>(
    (
      {
        id = uniqid(),
        error,
        label,
        className,
        wrapperClassName,
        labelClassName,
        showDynamicDataHelper,
        confirmationsEnabled,
        messagePreview,
        variant = 'primary',
        ...props
      },
      ref,
    ) => {
      return (
        <div className={classNames(classes.root, wrapperClassName)}>
          {label && (
            <label htmlFor={id}>
              <div
                className={classNames(classes.labelWrapper, {
                  [classes.single]: !showDynamicDataHelper,
                })}
              >
                <Typography
                  className={classNames(classes.label, labelClassName, {
                    [classes.labelError]: !!error,
                    [classes.labelSecondary]: variant === 'secondary',
                  })}
                  variant="body"
                >
                  {label}
                </Typography>
                {showDynamicDataHelper && <CreateReminderHelper />}
              </div>
            </label>
          )}
          <Box
            display="flex"
            gridGap={10}
            className={classNames(classes.wrapper, {
              [classes.wrapperDisabled]: props.disabled,
            })}
          >
            <textarea
              className={classNames(classes.input, classes.textarea, className, {
                [classes.inputSecondary]: variant === 'secondary',
                [classes.inputError]: !!error,
              })}
              ref={ref}
              id={id}
              {...props}
            />
            <div className={classes.messagePreviewWrapper}>
              <Typography variant="span" className={classes.messagePreview}>
                {messagePreview || 'Type a message to see the preview here'}
              </Typography>
              <div className={classes.confirmationLinkAlert}>
                {confirmationsEnabled ? 'Preview. The confirmation link is added automatically' : 'SMS message preview'}
              </div>
            </div>
          </Box>
          {error && (
            <Typography className={classes.error} variant="body">
              {error}
            </Typography>
          )}
          <div className={classNames(classes.countersWrapper)}>
            {/* Consumed reminder segments */}
            <ConsumedReminderSegments message={messagePreview} />
            {/* Current and max length of the message */}
            <Typography
              className={classNames(classes.sublabel, {
                [classes.error]: messagePreview.length > maxMessageLength,
                [classes.counterError]: messagePreview.length > maxMessageLength,
              })}
              variant="span"
            >
              <FormattedText
                id="configuration.step1.sublabel"
                values={{
                  current: messagePreview?.length ?? 0,
                  max: maxMessageLength,
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </Typography>
          </div>
        </div>
      )
    },
  ),
)

MessageInput.displayName = 'MessageInput'
