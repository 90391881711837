import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    AutoReminderCallout
} from 'src/modules/auto-reminders/auto-reminder-callout/auto-reminder-callout';
import { CalendarList } from 'src/modules/calendar/components';
import { FormattedText, Icon, Typography } from 'src/modules/core/components';
import { navigation } from 'src/modules/core/constants/navigation';
import { routeNames } from 'src/modules/core/constants/routeNames';
import { useSidebar } from 'src/modules/core/context/sidebar.context';
import classes from './sidebar.module.scss';

export function Sidebar() {
  const navigate = useNavigate()
  const location = useLocation()
  const { isCollapsed, setIsCollapsed } = useSidebar()
  const [isHovered, setIsHovered] = useState(false)

  const handleCollapseToggle = useCallback(() => {
    setIsCollapsed((prev) => !prev)
  }, [setIsCollapsed])

  const handleMouseEnter = useCallback(() => {
    if (isCollapsed) {
      setIsHovered(true)
    }
  }, [isCollapsed])

  const handleMouseLeave = useCallback(() => {
    if (isCollapsed) {
      setIsHovered(false)
    }
  }, [isCollapsed])

  const isExpanded = !isCollapsed || isHovered

  return (
    <div
      className={classNames(classes.root, {
        [classes.collapsed]: isCollapsed && !isHovered,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classes.header}>
        <Typography variant="h2" className={classes.title}>
          <FormattedText id="common.companyName" />
        </Typography>
        <button
          className={classNames(classes.collapseButton, {
            [classes.rotated]: isCollapsed,
          })}
          onClick={handleCollapseToggle}
        >
          <Icon name="arrowLeft" className={classes.collapseIcon} />
        </button>
      </div>

      <ul className={classes.sidebar}>
        {navigation.map((item) => (
          <li
            key={item.path}
            className={classNames(classes.sidebarItem, {
              [classes.active]: location.pathname === item.path,
            })}
            onClick={() => navigate(item.path)}
          >
            <Icon name={item.icon} className={classes.icon} />
            {isExpanded && (
              <Typography variant="body" className={classes.text}>
                {item.text}
              </Typography>
            )}
          </li>
        ))}
      </ul>
      {location.pathname === routeNames.dashboard.path && isExpanded && (
        <>
          <div className={classNames(classes.lineWrapper, classes.line)} />
          <div className={classes.wrapper}>
            <Typography variant="h4" className={classes.secondaryTitle}>
              <FormattedText id="calendars.dashboard.title" />
            </Typography>
          </div>
          <CalendarList listClassName={classes.calendarList} withUpgradeButton>
            <div className={classes.line} />
          </CalendarList>
        </>
      )}
      {isExpanded && <AutoReminderCallout />}
    </div>
  )
}
