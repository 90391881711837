import classNames from 'classnames';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import {
    useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { CountryData } from 'react-phone-input-2';
import generateRandomId from 'src/helpers/generate-random-id';
import { UserDto } from 'src/helpers/user.dto';
import { Button, Icon, PhoneNumberField } from 'src/modules/core/components';
import { DateContext } from 'src/modules/core/context';
import {
    getDefaultCountryCode, getPhoneAndCountryCode
} from 'src/modules/core/utils/phone-input.utils';
import {
    SendReminderImmediatelyModal
} from 'src/modules/reminder/components/send-reminder-immeditely-modal';
import { Box } from '@material-ui/core';
import { eventPreviewFields } from '../../constants/event-params';
import { useUpdateEvent } from '../../hooks/use-update-event';
import { EventPreview } from '../../types/event.types';
import {
    EventSuggestedNumbersHelper
} from '../../utils/EventSuggestedNumbersHelper';
import { getExpiredReminderOffsets } from '../../utils/helpers';
import { EditEventForm } from './event-card';
import classes from './event-card-phone-fields.module.scss';

export type EventCardPhoneFieldsProps = {
  profile: UserDto
  event: EventPreview
  hasFocus?: boolean
}

export function EventCardPhoneFields({ profile, event, hasFocus }: EventCardPhoneFieldsProps) {
  const eventDate = useMemo(() => new Date(Number(event.event_date)), [event.event_date])
  const timings = profile.default_time_before

  // Add a forceUpdate state to trigger re-render when needed
  const [, forceUpdate] = useState({})

  const { startDate, endDate } = useContext(DateContext)
  const params = useMemo(
    () => ({
      fields: eventPreviewFields,
      start_date: startDate,
      end_date: endDate,
    }),
    [startDate, endDate],
  )
  const updateEvent = useUpdateEvent(params)

  const eventPhoneNumbers = Array.from(
    new Set([...(event?.receiver_numbers ?? []), ...EventSuggestedNumbersHelper.getPendingSuggestions(event)]),
  ).filter(Boolean)
  const defaultReceiverNumbers = useMemo(
    () => (profile.usage_mode === 'personal' ? profile.phone_numbers : eventPhoneNumbers ?? []),
    [profile.usage_mode, profile.phone_numbers, eventPhoneNumbers],
  )
  if (defaultReceiverNumbers.length === 0) {
    defaultReceiverNumbers.push('')
  }

  const {
    control,
    watch,
    formState: { isValid, isDirty, errors },
    handleSubmit: onSubmit,
    setError,
    clearErrors,
  } = useForm<EditEventForm>({
    defaultValues: {
      dummy: '',
      receiverNumbers: defaultReceiverNumbers.map((n) => ({
        id: generateRandomId(),
        ...getPhoneAndCountryCode(n, getDefaultCountryCode(profile)),
      })),
    },
  })

  const {
    fields: phones,
    append: addPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'receiverNumbers',
  })

  function validatePhoneNumber(phone: string, country: string) {
    if (!phone) return 'Phone number is required'
    const parsed = parsePhoneNumberFromString(phone, country as CountryCode)
    if (!parsed || !parsed.isValid()) {
      return 'Invalid phone number'
    }
    return true
  }

  const [phonesAreValid, setPhonesAreValid] = useState(false)
  const validatePhoneNumbers = useCallback(() => {
    let isValid = false
    watch('receiverNumbers').forEach((phoneField, index) => {
      const result = validatePhoneNumber(phoneField.phone, phoneField.country)
      if (result === true) {
        isValid = true
        clearErrors(`receiverNumbers.${index}.phone`)
        localStorage.setItem('defaultCountryCode', phoneField.country)
      } else {
        isValid = false
        setError(`receiverNumbers.${index}.phone`, { type: 'manual', message: result })
      }
    })
    setPhonesAreValid(isValid)
  }, [watch, setError, clearErrors])

  useEffect(() => {
    validatePhoneNumbers()
  }, [validatePhoneNumbers])

  function isSuggested(suggested: string) {
    return event?.suggested_numbers?.includes(suggested) && !event?.accepted_suggested_numbers?.includes(suggested)
  }

  function hasSuggested() {
    return event?.suggested_numbers?.some(
      (suggested) =>
        phones.some((p) => p.phone === suggested) && !event?.accepted_suggested_numbers?.includes(suggested),
    )
  }

  function handleAddPhone() {
    const countryCode = getDefaultCountryCode(profile)
    addPhone({
      id: generateRandomId(),
      phone: '',
      country: countryCode,
    })
  }

  function handleRemovePhone(index: number) {
    removePhone(index)
    validatePhoneNumbers()
  }

  function handleStackClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
  }

  const sendImmediatelyRef = useRef<'closed' | 'open' | 'send'>('closed')

  const handleSubmit = useCallback(
    (data: EditEventForm) => {
      console.log('SUBMIT...')
      if (eventDate < new Date()) {
        setError('dummy', { type: 'manual', message: 'Event is in the past' })
        return
      }

      if (!profile.default_time_before?.length) {
        setError('dummy', { type: 'manual', message: 'Must have at least one reminder' })
        return
      }

      let send_reminder_immediately = false

      if (sendImmediatelyRef.current === 'send') {
        send_reminder_immediately = true
        sendImmediatelyRef.current = 'closed'
      } else {
        const expiredTimings = getExpiredReminderOffsets(eventDate, timings)
        if (expiredTimings.length > 0) {
          sendImmediatelyRef.current = 'open'
          return
        }
      }

      if (!isValid) {
        return
      }

      updateEvent.mutate({
        id: event.id,
        active: true,
        suggestions_reviewed: true,
        send_reminder_immediately,
        receiver_numbers: data.receiverNumbers.map((p) => p.phone.trim()).filter((p) => p && p !== '+'),
      })
    },
    [updateEvent, event.id, eventDate, timings, isValid, profile.default_time_before?.length, setError],
  )

  useEffect(() => {
    if (sendImmediatelyRef.current === 'send') {
      onSubmit(handleSubmit)()
    }
  }, [sendImmediatelyRef, handleSubmit, onSubmit])

  const [showErrors, setShowErrors] = useState(false)
  const showErrorsTimeoutRef = useRef(null)
  useEffect(() => () => clearTimeout(showErrorsTimeoutRef.current), [])
  function handlePhoneInputChange() {
    clearTimeout(showErrorsTimeoutRef.current)
    setShowErrors(false)
    showErrorsTimeoutRef.current = setTimeout(() => setShowErrors(true), 800)
  }

  function hasErrors() {
    return !isValid || Object.keys(errors).length > 0
  }

  function getErrorMessage() {
    if (!Object.keys(errors).length) return null
    if (errors.receiverNumbers) {
      const messages = (errors.receiverNumbers as any)
        .filter((e: any) => e?.phone?.message)
        .map((e: any) => e.phone.message)
      return Array.from(new Set(messages)).join(', ')
    }
    if (errors.dummy) return errors.dummy.message
  }

  if (profile.usage_mode !== 'customer') {
    return null
  }

  return (
    <>
      <div onClick={handleStackClick}>
        <form onSubmit={onSubmit(handleSubmit)} className={classes.stack}>
          <span className={classes.title}>Recipent phone number</span>
          {hasSuggested() && <span className={classes.suggestedTitle}>Suggested numbers in pink</span>}
          <div className={classes.phoneInputStack}>
            {phones.map((phone, index) => (
              <div className={classes.phoneInputContainer} key={phone.id}>
                <Controller
                  control={control}
                  name={`receiverNumbers.${index}`}
                  defaultValue={phone}
                  render={(props) => (
                    <PhoneNumberField
                      value={phone.phone}
                      country={phone.country}
                      error={isDirty && errors.receiverNumbers && errors.receiverNumbers[index]?.phone.message}
                      onChange={(value: string, country: CountryData) => {
                        handlePhoneInputChange()
                        props.field.onChange({
                          country: country.countryCode,
                          phone: `+${country.dialCode.replace(/\D/g, '')}${value.replace(/\D/g, '')}`,
                        })
                        validatePhoneNumbers()
                      }}
                      onEnterKeyPress={onSubmit(handleSubmit)}
                      inputClass={classNames(classes.phoneInput, {
                        [classes.suggestedPhoneInput]: isSuggested(phone.phone),
                      })}
                    />
                  )}
                />
                {index > 0 && (
                  <Icon
                    name="removeEntry"
                    className={classes.btnRemoveRecipient}
                    onClick={() => handleRemovePhone(index)}
                  />
                )}
              </div>
            ))}
          </div>
          <button type="button" className={classes.btnAddRecipient} onClick={handleAddPhone}>
            Add another recipient
          </button>

          {hasFocus && hasErrors() && showErrors && (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={3}>
              <Icon name="eventCardError" />
              <span className={classes.error}>{getErrorMessage()}</span>
            </Box>
          )}

          {hasFocus && event.errors.length > 0 && !event.active && (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={3}>
              <Icon name="eventCardError" />
              <span className={classes.error}>{event.errors.join(', ')}</span>
            </Box>
          )}

          {hasFocus && (
            <Button type="submit" disabled={!phonesAreValid} className={classes.btnSave}>
              Save and activate
            </Button>
          )}
        </form>
      </div>

      {sendImmediatelyRef.current === 'open' && (
        <SendReminderImmediatelyModal
          reminderTiming={getMinExpiredReminderOffset(eventDate, timings)}
          onClose={() => {
            sendImmediatelyRef.current = 'closed'
            forceUpdate({}) // Force a re-render to close the modal
          }}
          onBack={() => {
            sendImmediatelyRef.current = 'closed'
            forceUpdate({}) // Force a re-render to close the modal
          }}
          onSend={() => {
            sendImmediatelyRef.current = 'send'
            onSubmit(handleSubmit)()
          }}
        />
      )}
    </>
  )
}

function getMinExpiredReminderOffset(eventDate: Date, timingValues: number[]): number {
  // This is for showing the send immediately modal
  // Suppose the event starts in 35 minutes, and the user has set reminders for 1d, 1h and 30m
  // We want to return the smallest offset that is in the past, which is 1h
  // This will cause the modal to show: "1h reminder is in the past, set a reminder for 30m or less"
  const expiredOffsets = getExpiredReminderOffsets(eventDate, timingValues)
  return expiredOffsets.length ? Math.min(...expiredOffsets) : 0
}
