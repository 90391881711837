import 'react-phone-input-2/lib/style.css';
import classNames from 'classnames';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { CountryData } from 'react-phone-input-2';
import { createYupResolver } from 'src/helpers/custom-yup-resolver';
import generateRandomId from 'src/helpers/generate-random-id';
import {
    Button, FormattedText, FormControl, Icon, Loader, Modal, PhoneNumberField,
    TextField, Typography
} from 'src/modules/core/components';
import {
    getDefaultCountryCode, getPhoneAndCountryCode
} from 'src/modules/core/utils/phone-input.utils';
import {
    maxMessageLength, maxMessageLengthWithConfirmation
} from 'src/modules/event/constants/event.constants';
import { eventEditSchema } from 'src/modules/event/schemas/event-edit.schema';
import {
    formatEventDate, generateEventMessage
} from 'src/modules/event/utils/helpers';
import {
    ConsumedReminderSegments, CreateReminderHelper, PreviewReminderModal
} from 'src/modules/reminder/components';
import { Box } from '@material-ui/core';
import classes from './send-test-reminder-form-modal.module.scss';

import type {
  SendTestReminderForm,
  SendTestReminderFormData,
  SendTestReminderModalContentProps,
  SendTestReminderModalProps,
  SendTestReminderPayload,
} from './send-test-reminder-form-modal.interface'
import type { EventData } from 'src/modules/event/types/event.types'
const MAX_RECEIVER_NUMBERS_AMOUNT = 10

export const SendTestReminderFormModal: FC<SendTestReminderModalProps> = ({
  onClose,
  modalTitle,
  isLoading,
  ...props
}) => {
  if (isLoading) {
    return (
      <Modal
        className="translate"
        onClose={onClose}
        title={
          <Typography variant="h3" className={classes.modalTitle}>
            {modalTitle}
          </Typography>
        }
      >
        <Loader isLoading={isLoading} />
      </Modal>
    )
  }
  return <SendTestReminderModalContent onClose={onClose} modalTitle={modalTitle} {...props} />
}

export const SendTestReminderModalContent: FC<SendTestReminderModalContentProps> = ({
  profileData,
  event,
  onClose,
  onSend,
  modalTitle,
  error,
}) => {
  const defaultCountryCode = getDefaultCountryCode(profileData)

  const defaultReceiverNumbers = useMemo(
    () =>
      profileData.usage_mode === 'personal'
        ? profileData.phone_numbers
        : event?.receiver_numbers?.filter(Boolean) ?? [],
    [profileData, event],
  )

  if (defaultReceiverNumbers.length === 0) defaultReceiverNumbers.push('')

  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm<SendTestReminderForm>({
    defaultValues: {
      text: event?.default_message_text ? profileData?.default_message_text : event?.text || '',
      location: event?.default_location ? profileData?.default_location : event?.location || '',
      receiver_numbers: defaultReceiverNumbers.map((n) => ({
        id: generateRandomId(),
        ...getPhoneAndCountryCode(n, getDefaultCountryCode(profileData)),
      })),
    },
    resolver: createYupResolver(eventEditSchema),
  })

  const [isPreview, setIsPreview] = useState(false)
  const [previewReminder, setPreviewReminder] = useState<EventData>(event)
  const [amountOfReceiverNumbers] = useState(defaultReceiverNumbers.length || 1)

  const { formatMessage } = useIntl()
  const { text } = watch()

  const eventDate = useMemo(() => new Date(parseInt(event.event_date, 10)), [event.event_date])
  const isPastEvent = useMemo(() => new Date() > eventDate, [eventDate])
  const maxTextLength = profileData?.appointment_confirmation ? maxMessageLengthWithConfirmation : maxMessageLength

  const eventMessage = useMemo(
    () => generateEventMessage({ ...profileData, appointment_confirmation: false }, { ...previewReminder, text }),
    [previewReminder, profileData, text],
  )

  const handleCloseModal = useCallback(() => setIsPreview(false), [])

  const {
    fields: phoneNumbers,
    append: addPhoneNumber,
    remove: removePhoneNumber,
  } = useFieldArray({
    control,
    name: 'receiver_numbers',
  })

  const handleAddPhoneNumber = () => {
    if (phoneNumbers.length < MAX_RECEIVER_NUMBERS_AMOUNT) {
      addPhoneNumber({ id: generateRandomId(), country: defaultCountryCode, phone: '' })
    }
  }

  useEffect(() => {
    const subscription = watch((value) =>
      setPreviewReminder((prev) => ({
        ...prev,
        ...value,
        receiver_numbers: value.receiver_numbers.map((pn) => pn.phone),
      })),
    )
    return () => subscription.unsubscribe()
  }, [watch])

  const onSubmit = (data: SendTestReminderFormData) => {
    // Validate message length
    const maxLength = profileData.appointment_confirmation ? maxMessageLengthWithConfirmation : maxMessageLength
    if (eventMessage.length > maxLength) {
      setError('text', { type: 'custom', message: `Default SMS Reminder must be shorter than ${maxLength} characters` })
      return
    }

    // Construct payload
    const payload: SendTestReminderPayload = {
      event_id: event.id,
      title: event.title,
      event_date: event.event_date,
      sender_name: profileData.sender_name,
      location: data.location,
      text: data.text,
      receiver_numbers: [],
    }

    // Ensure unique phone numbers and save last country code
    const seenNumbers = new Set()
    for (const receiver of data.receiver_numbers) {
      const phoneNumber = `+${receiver.phone.replace(/\D/g, '')}`
      if (!seenNumbers.has(phoneNumber) && phoneNumber.length >= 10) {
        seenNumbers.add(phoneNumber)
        payload.receiver_numbers.push(phoneNumber)
        if (receiver.country) {
          localStorage.setItem('defaultCountryCode', receiver.country)
        }
      }
    }

    onSend(payload)
  }

  return (
    <>
      <Modal
        className="translate"
        onClose={onClose}
        title={
          <Typography variant="h3" className={classes.modalTitle}>
            {modalTitle}
          </Typography>
        }
      >
        <form className={classNames(classes.root, { [classes.hidden]: isPreview })} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.header}>
            <div>
              <Typography variant="h2" className={classes.title}>
                {event.title || <FormattedText id="reminder.noTitle" />}
              </Typography>
              <Typography variant="body" className={classes.regular}>
                {formatEventDate(eventDate, profileData.google_calendar_date_order)},{' '}
                {event.all_day ? <FormattedText id="reminder.allDay" /> : format(eventDate, 'h:mma')}
              </Typography>
            </div>
          </div>
          {error && (
            <Box marginBottom={3}>
              <Typography variant="body" className={classes.error}>
                {error}
              </Typography>
            </Box>
          )}
          {phoneNumbers.map((phone, index) => (
            <FormControl
              label={`${formatMessage({ id: 'onboarding.sendTestReminder.form.phone.label' })} ${index + 1}`}
              error={errors.receiver_numbers?.[index]?.phone.message}
              className={classes.textFieldWrapper}
              key={phone.id}
            >
              <Controller
                control={control}
                name={`receiver_numbers.${index}`}
                defaultValue={phone}
                render={(props) => (
                  <Box display="flex" alignItems="center" gridColumnGap={10}>
                    <PhoneNumberField
                      disableDropdown={isPastEvent}
                      disabled={isPastEvent}
                      value={phone.phone}
                      country={phone.country}
                      error={errors.receiver_numbers?.[index]?.phone.message}
                      onChange={(val, country: CountryData) => {
                        props.field.onChange({
                          country: country.countryCode,
                          phone: `+${country.dialCode.replace(/\D/g, '')}${val.replace(/\D/g, '')}`,
                        })
                      }}
                      inputClass={classes.phoneNumberInput}
                    />
                    {index > 0 && (
                      <span onClick={() => removePhoneNumber(index)}>
                        <Icon name="close" className={classes.removeButton} />
                      </span>
                    )}
                  </Box>
                )}
              />
            </FormControl>
          ))}
          {amountOfReceiverNumbers < MAX_RECEIVER_NUMBERS_AMOUNT && (
            <Typography variant="body" className={classes.textButton} onClick={handleAddPhoneNumber}>
              + <FormattedText id="configuration.editReminderModal.textButton.addPhone" />
            </Typography>
          )}
          <TextField
            disabled={isPastEvent}
            wrapperClassName={classes.textFieldWrapper}
            label="Meeting Location"
            Element="textarea"
            {...register('location')}
            error={errors.location?.message}
          />
          <TextField
            wrapperClassName={classNames({ [classes.textFieldWrapper]: isPastEvent })}
            disabled={isPastEvent}
            label={formatMessage({ id: 'configuration.editReminderModal.label.message' })}
            Element="textarea"
            rows={3}
            {...register('text')}
            error={errors.text?.message}
          >
            {!isPastEvent && (
              <ConsumedReminderSegments
                appointmentConfirmation={profileData.appointment_confirmation}
                message={eventMessage}
              />
            )}
          </TextField>
          <div className={classNames(classes.textWrapper, classes.textFieldWrapper)}>
            {!isPastEvent && <CreateReminderHelper />}
            {!!(eventMessage && eventMessage.length) && !isPastEvent && (
              <Typography
                className={classNames(classes.sublabel, { [classes.error]: eventMessage.length > maxTextLength })}
                variant="span"
              >
                <FormattedText
                  id="configuration.step1.sublabel"
                  values={{
                    current: eventMessage.length,
                    max: maxTextLength,
                    strong: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </Typography>
            )}
          </div>
          <Button type="submit" className={classes.button} widthType="content" disabled={isPastEvent}>
            <FormattedText id="onboarding.sendTestReminder.form.button" />
          </Button>
        </form>
      </Modal>
      {previewReminder && isPreview && (
        <PreviewReminderModal
          message={generateEventMessage(profileData, previewReminder)}
          onClose={handleCloseModal}
          onBack={handleCloseModal}
        />
      )}
    </>
  )
}
