import classNames from 'classnames';
import { addDays, addMilliseconds, format } from 'date-fns';
import Tooltip from 'rc-tooltip';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import {
    Button, ErrorModal, FormattedText, Icon, Loader, Typography
} from 'src/modules/core/components';
import { DateContext } from 'src/modules/core/context';
import { EventCard } from 'src/modules/event/components';
import { TabSelect } from 'src/modules/event/components/event-list/tab-select';
import { groupEventsByDate } from 'src/modules/event/utils/helpers';
import { useProfile } from 'src/modules/user/hooks/use-profile';
import classes from './reminder-list.module.scss';

import type { ErrorMessage } from 'src/modules/core/types/error.types'
import type { EventPreviewWithReminders } from 'src/modules/event/types/event.types'

import type { EventListProps } from './reminder-list.interface'

export const numberPerPage = 5
const offsetRight = 0
const offsetBottom = 14

export const ReminderList: FC<EventListProps> = ({ events, changeSelectedDate, isLoading }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null)

  const { daysCount } = useContext(DateContext)

  const profile = useProfile()

  const reminders = useMemo(() => {
    return listPastRemindersForEvents(events)
  }, [events])

  const filteredEvents = useMemo(() => {
    return groupEventsByDate(reminders, addDays(selectedDate, 0), addDays(selectedDate, numberPerPage))
  }, [reminders, selectedDate])

  const onDateChange = useCallback(
    (number: number) => () => {
      setSelectedDate((prevState) => addDays(prevState, number))
      changeSelectedDate && changeSelectedDate((prevState) => addDays(prevState, number))
    },
    [changeSelectedDate],
  )

  const handleReturnToTodayClick = useCallback(() => {
    const today = new Date()
    setSelectedDate(today)
    today.setHours(0, 0, 0, 0)
    changeSelectedDate && changeSelectedDate(today)
  }, [setSelectedDate, changeSelectedDate])

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <div className={classes.dateHeading}>
          <div className={classes.leftButtons}>
            <div className={classes.arrowWrapper} onClick={onDateChange(-daysCount)}>
              <Icon name="arrowLeftRounded" className={classes.arrow} />
            </div>
            <Tooltip
              placement="bottom"
              mouseEnterDelay={0.1}
              motion={{ motionName: 'example' }}
              align={{ offset: [offsetRight, offsetBottom] }}
              overlayClassName={classes.tooltipOverlay}
              overlay={
                <Typography variant="span" className={classes.bodyText}>
                  {format(new Date(), 'EEEE, LLLL d')}
                </Typography>
              }
            >
              <Button className={classes.button} onClick={handleReturnToTodayClick}>
                <FormattedText id="dashboard.button.today" />
              </Button>
            </Tooltip>
          </div>
          <div className={classes.arrowWrapper} onClick={onDateChange(daysCount)}>
            <Icon name="arrowLeftRounded" className={classNames(classes.arrow, classes.arrowRight)} />
          </div>
          <Typography variant="h2" className={classes.month}>
            {format(selectedDate, 'MMMM uuuu')}
          </Typography>
        </div>
        <TabSelect />
      </div>
      <div className={classes.remindersList}>
        <Loader className={classes.loader} isLoading={isLoading} />
        {!isLoading &&
          filteredEvents.map((dayEvents) => (
            <div key={dayEvents.date} className={classes.remindersListItem}>
              <Typography className={classes.dayTitle} variant="body">
                {dayEvents.date}
              </Typography>
              <div>
                {dayEvents.events.map((event) => (
                  <div key={`${event.id}-${event.event_date}`} className={classes.reminder}>
                    <EventCard
                      showBadge={profile.data?.appointment_confirmation}
                      showSwitch
                      event={event}
                      eventRepresentsReminder={true}
                      variant="finished"
                    />
                  </div>
                ))}
                {dayEvents.events.length === 0 && (
                  <Typography variant="body" className={classes.textEmpty}>
                    <FormattedText id="reminder.text.empty" />
                  </Typography>
                )}
              </div>
            </div>
          ))}
      </div>
      {errorMessage && (
        <ErrorModal
          title={
            <Typography variant="body" className={classes.errorTitle}>
              {errorMessage.title}
            </Typography>
          }
          description={
            <Typography variant="span" className={classes.errorDescription}>
              {errorMessage.description}
            </Typography>
          }
          onClose={() => setErrorMessage(null)}
        />
      )}
    </div>
  )
}

function listPastRemindersForEvents(events: EventPreviewWithReminders[]) {
  const sentReminders: EventPreviewWithReminders[] = []

  events?.forEach((event) => {
    event.reminders.forEach((timeBefore) => {
      const reminderTime = addMilliseconds(new Date(parseInt(event.event_date, 10)), -timeBefore.time_before).getTime()

      // Discard future reminders
      if (reminderTime > new Date().getTime()) {
        return
      }

      sentReminders.push({
        ...event,
        id: `${event.id}-${timeBefore.time_before}`,
        event_date: reminderTime.toString(),
      })
    })
  })

  return sentReminders
}
