import 'yup-phone';
import {
    isOnlySpaces, maxLength
} from 'src/modules/user/constants/configuration-form';
import * as yup from 'yup';

export const phoneLength = 5

export const eventEditSchema = yup.object().shape(
  {
    sender_name: yup
      .string()
      .nullable()
      .notRequired()
      .when('sender_name', {
        is: (value) => value?.length,
        then: (rule) =>
          rule.max(maxLength, 'From must be shorter than 160 characters').matches(isOnlySpaces, {
            excludeEmptyString: true,
            message: 'From cannot contain only blank spaces',
          }),
      }),
    receiver_numbers: yup.array().of(
      yup.object().shape({
        country: yup.string().nullable(),
        phone: yup
          .string()
          .required('Customer number cannot be empty')
          .min(phoneLength, 'Invalid customer number length')
          .phone('', false, 'Customer number must be a valid phone number'),
      }),
    ),
    location: yup
      .string()
      .nullable()
      .max(maxLength, 'Meeting location must be shorter than 160 characters')
      .matches(isOnlySpaces, {
        excludeEmptyString: true,
        message: 'Meeting location cannot contain only blank spaces',
      }),
    text: yup.string().nullable().required('Message cannot be empty').matches(isOnlySpaces, {
      excludeEmptyString: true,
      message: 'Message cannot contain only blank spaces',
    }),
    time_before: yup.array().min(1, 'Timing field cannot be empty').typeError('Timing field cannot be empty'),
  },
  [['sender_name', 'sender_name']],
)
