import {
    addDays, addMinutes, differenceInDays, format, isToday as isTodayDateFns
} from 'date-fns';
import RRule, { RRuleSet, rrulestr } from 'rrule';
import { EventPreview, ExtendEventPreview } from '../types/event.types';
import { updateRruleExdates } from './helpers';

export function groupEventsByDate(
  events: EventPreview[],
  startDate: Date,
  endDate: Date,
): { date: string; isToday: boolean; events: ExtendEventPreview[] }[] {
  startDate = onlyDate(startDate)
  endDate = onlyDate(endDate)

  const totalDays = differenceInDays(endDate, startDate)

  const [nonRecurringEvents, recurringEvents] = splitEvents(events)

  const groupedEvents = Array.from({ length: totalDays }).map((_, i) => {
    const currentDate = addDays(startDate, i)

    // Generate occurrences only for this single day
    const dayStart = currentDate
    const dayEnd = addMinutes(addDays(currentDate, 1), -1)

    const expandedRecurringEvents = expandRecurringEventsForDay(recurringEvents, dayStart, dayEnd)
    const allEvents = [...expandedRecurringEvents, ...nonRecurringEvents]

    return {
      date: format(currentDate, 'EEE, LLL d'),
      isToday: isTodayDateFns(currentDate),
      events: allEvents
        .filter((event) => isEventOnSameDay(currentDate, event))
        .sort((event1, event2) => compareEventsByTime(event1, event2)),
    }
  })

  return groupedEvents
}

function splitEvents(events: EventPreview[]): [EventPreview[], EventPreview[]] {
  const isGoogleEvent = (event: any) => Boolean(event.google_event_date)

  // When listing from Google, we don't need to split events, treat
  // all events as non-recurring, because Google already expanded the events
  // into individual occurrences and we don't need to expand them again.
  const nonRecurringEvents = events
    .filter((event) => (isGoogleEvent(event) ? true : !event.recurrence_rules))
    .map((event) => mergeParentIfRecurringChild(event, events))

  // Otherwise, we need to handle recurring events ourselves.
  const recurringEvents = events.filter((event) => !isGoogleEvent(event) && event.recurrence_rules)

  return [nonRecurringEvents, recurringEvents]
}

function onlyDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

function mergeParentIfRecurringChild(event: EventPreview, events: EventPreview[]): ExtendEventPreview {
  const original_event_date = event.event_date
  if (event.recurring_event_id !== null) {
    const parentEvent = events.find((e) => e.event_id === event.recurring_event_id)
    if (parentEvent) {
      return { ...parentEvent, ...event, original_event_date }
    }
  }
  return { ...event, original_event_date }
}

function expandRecurringEventsForDay(recurringEvents: EventPreview[], startDate: Date, endDate: Date): EventPreview[] {
  return recurringEvents.flatMap((recurringEvent) => {
    const recurrenceRuleSet = buildRuleSetForEvent(recurringEvent)
    const occurrences = recurrenceRuleSet.between(startDate, endDate, true)

    return occurrences.map((occurrenceDateUTC) => ({
      ...recurringEvent,
      event_date: applyOriginalEventTime(occurrenceDateUTC, recurringEvent.event_date).toString(),
      original_event_date: recurringEvent.event_date,
    }))
  })
}

function buildRuleSetForEvent(recurringEvent: EventPreview): RRuleSet {
  const rules = [...recurringEvent.recurrence_rules]
  const originalEventDateUTC = new Date(parseInt(recurringEvent.event_date, 10))

  rules.push(RRule.optionsToString({ dtstart: originalEventDateUTC }))

  const recurrenceRuleSet = rrulestr(rules.join('\n')) as RRuleSet
  updateExdatesIfNeeded(recurrenceRuleSet, originalEventDateUTC, 0)

  return recurrenceRuleSet
}

function updateExdatesIfNeeded(ruleSet: RRuleSet, dtstart: Date, offset: number): void {
  const extendedRuleSet = ruleSet as RRuleSet & { _exdate: Date[] }
  if (extendedRuleSet._exdate) {
    extendedRuleSet._exdate = updateRruleExdates(dtstart, ruleSet, false, 0)
  }
}

function applyOriginalEventTime(occurrence: Date, originalEventDateString: string): number {
  const originalEventDate = new Date(parseInt(originalEventDateString, 10))

  occurrence.setUTCHours(originalEventDate.getUTCHours())
  occurrence.setUTCMinutes(originalEventDate.getUTCMinutes())
  occurrence.setUTCSeconds(originalEventDate.getUTCSeconds())
  occurrence.setUTCMilliseconds(originalEventDate.getUTCMilliseconds())

  return occurrence.getTime()
}

function isEventOnSameDay(currentDay: Date, event: EventPreview): boolean {
  let eventDate: Date
  if ((event as any).google_event_date) {
    // For all-day events, google_event_date is a date-only ISO string
    if (event.all_day) {
      const [year, month, day] = (event as any).google_event_date.split('-').map(Number)
      eventDate = new Date(year, month - 1, day) // month is 0-based in Date constructor
    } else {
      eventDate = new Date((event as any).google_event_date)
    }
  } else {
    eventDate = new Date(parseInt(event.event_date, 10))
  }

  return (
    eventDate.getFullYear() === currentDay.getFullYear() &&
    eventDate.getMonth() === currentDay.getMonth() &&
    eventDate.getDate() === currentDay.getDate()
  )
}

function compareEventsByTime(event1: EventPreview, event2: EventPreview): number {
  // All-day events should be sorted to the top
  if (event1.all_day && !event2.all_day) {
    return -1
  }
  if (!event1.all_day && event2.all_day) {
    return 1
  }
  // If both events are all-day, sort by title
  if (event1.all_day && event2.all_day) {
    return (event1.title ?? '').localeCompare(event2.title ?? '')
  }
  // Otherwise, sort by event_date
  const time1 = parseInt(event1.event_date, 10)
  const time2 = parseInt(event2.event_date, 10)
  return time1 - time2
}
