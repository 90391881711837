import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { TgglProvider } from 'react-tggl-client';
import { TagManagerWrapper } from 'src/modules/core/components';
import {
    I18nProvider, LoadingWrapperProvider, QueryingProvider
} from 'src/modules/core/providers';
import { Router } from 'src/modules/core/router';
import { AuthProvider } from 'src/modules/user/providers';
import * as amplitude from '@amplitude/analytics-browser';
import { ErrorBoundary } from './DatadogErrorBoundary';
import { tgglClient } from './modules/core/providers/tggl-client';

amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, undefined, {
  defaultTracking: false,
})

const App: FC = () => {
  return (
    <BrowserRouter>
      <TgglProvider
        client={tgglClient}
        onFlagEvaluation={({ slug, active, value }) => {
          amplitude.track('Flag evaluated', { slug, active, value })
        }}
      >
        <QueryingProvider>
          <I18nProvider>
            <SnackbarProvider maxSnack={3}>
              <LoadingWrapperProvider>
                <AuthProvider>
                  <TagManagerWrapper>
                    <Router />
                  </TagManagerWrapper>
                </AuthProvider>
              </LoadingWrapperProvider>
            </SnackbarProvider>
          </I18nProvider>
        </QueryingProvider>
      </TgglProvider>
    </BrowserRouter>
  )
}

const MyApp = () => (
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)

export default MyApp
