import {
    createContext, ReactNode, useCallback, useContext, useState
} from 'react';

interface SidebarContextType {
  isCollapsed: boolean
  setIsCollapsed: (value: boolean | ((prev: boolean) => boolean)) => void
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined)

interface SidebarProviderProps {
  children: ReactNode
}

export function SidebarProvider({ children }: SidebarProviderProps) {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const saved = localStorage.getItem('sidebarCollapsed')
    return saved ? JSON.parse(saved) : false
  })

  const handleSetIsCollapsed = useCallback(
    (value: boolean | ((prev: boolean) => boolean)) => {
      setIsCollapsed(value)
      const newValue = typeof value === 'function' ? value(isCollapsed) : value
      localStorage.setItem('sidebarCollapsed', JSON.stringify(newValue))
    },
    [isCollapsed],
  )

  return (
    <SidebarContext.Provider value={{ isCollapsed, setIsCollapsed: handleSetIsCollapsed }}>
      {children}
    </SidebarContext.Provider>
  )
}

export function useSidebar() {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }
  return context
}
