import ms from 'ms';
/* eslint-disable react/no-unescaped-entities */
import { FC } from 'react';
import {
    Button, FormattedText, Modal, Typography
} from 'src/modules/core/components';
import { timingOptions } from 'src/modules/user/constants/profile.constants';
import { Box } from '@material-ui/core';
import classes from './send-reminder-immeditely-modal.module.scss';

import type { SendReminderImmediatelyModalProps } from './send-reminder-immeditely-modal.interface'
export const SendReminderImmediatelyModal: FC<SendReminderImmediatelyModalProps> = ({
  reminderTiming,
  onSend,
  onClose,
  onBack,
}) => {
  if (!reminderTiming) {
    onBack()
    return null
  }

  const friendlyTiming = timingOptions.find((option) => +option.value === reminderTiming)?.label

  if (!friendlyTiming) {
    onBack()
    return null
  }

  const lesserTiming = timingOptions.find((option) => Number(option.value) < Number(reminderTiming))?.label

  return (
    <Modal
      variant="withBack"
      onClose={onClose}
      onBack={onBack}
      title={
        <Typography variant="h3" className={classes.modalTitle}>
          Send now?
        </Typography>
      }
    >
      <Box marginBottom={3}>
        <Typography variant="body" className={classes.message}>
          The reminder for <strong>{friendlyTiming}</strong> is in the past.{' '}
          {lesserTiming && (
            <>
              Set a reminder for <strong>{lesserTiming}</strong> or less.{' '}
            </>
          )}
          <strong>You can {lesserTiming ? 'also' : ''} choose to send the reminder immediately.</strong>
        </Typography>
      </Box>
      <Box display="flex" gridGap={12}>
        <Button variant="secondary" onClick={onBack}>
          Back to editing
        </Button>
        <Button onClick={onSend}>Send now</Button>
      </Box>
    </Modal>
  )
}
