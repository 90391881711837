import { FC, useState } from 'react';
import {
    AppointmentConfirmationStepsModal
} from 'src/modules/confirmation/containers';
import {
    FormattedText, Icon, ToggleSwitch, Typography
} from 'src/modules/core/components';
import { SettingsProfileInfoForm } from 'src/modules/user/components';
import { timingOptions } from 'src/modules/user/constants/profile.constants';
import { SettingsBlockWrapper } from 'src/modules/user/containers';
import { Box } from '@material-ui/core';
import classes from './settings-profile-info.module.scss';

import type { SettingsProfileInfoProps } from './settings-profile-info.interface'

export const SettingsProfileInfoCustomerMode: FC<SettingsProfileInfoProps> = ({ profileInfo }) => {
  const [isStepsModalOpen, setIsStepsModalOpen] = useState(false)
  const [isProfileInfoEditing, setIsProfileInfoEditing] = useState<boolean>(false)

  const handleChangeProfileInfo = () => {
    setIsProfileInfoEditing(true)
  }

  return (
    <>
      <SettingsBlockWrapper title={<FormattedText id="settings.title.profile" />} onClick={handleChangeProfileInfo}>
        <div className={classes.profileInfo}>
          {isProfileInfoEditing ? (
            <SettingsProfileInfoForm profileInfo={profileInfo} setIsProfileInfoEditing={setIsProfileInfoEditing} />
          ) : (
            <>
              <div className={classes.infoBlock}>
                <Typography className={classes.infoLabel} variant="span">
                  <FormattedText id="settings.label.businessName" />
                </Typography>
                <Typography variant="body" className={classes.infoText}>
                  {profileInfo?.sender_name}
                </Typography>
              </div>
              <div className={classes.infoBlock}>
                <Typography className={classes.infoLabel} variant="span">
                  <FormattedText id="configuration.step1.label.location" />
                </Typography>
                <Typography variant="body" className={classes.infoText}>
                  {profileInfo?.default_location}
                </Typography>
              </div>
              <div className={classes.infoBlock}>
                <Typography className={classes.infoLabel} variant="span">
                  <FormattedText id="configuration.step1.label.reminder" />
                </Typography>
                <Typography variant="body" className={classes.infoText}>
                  {profileInfo?.default_message_text}
                </Typography>
              </div>
              <div className={classes.infoBlock}>
                <Typography className={classes.infoLabel} variant="span">
                  <FormattedText id="configuration.step1.timing.title" />
                </Typography>
                <Typography variant="body" className={classes.infoText}>
                  {profileInfo?.default_time_before
                    ?.map((t) => {
                      const currentTiming = timingOptions.find((option) => +option.value === t)

                      return `${currentTiming.label} before`
                    })
                    .join(', ')}
                </Typography>
                <div className={classes.infoBlock}>
                  <Box display="flex" alignItems="center" gridColumnGap={5}>
                    <Typography className={classes.infoLabel} variant="span">
                      <FormattedText id="settings.appointment.confirmation" />
                    </Typography>
                    <Icon
                      name="questionMark"
                      className={classes.tooltipLink}
                      onClick={() => setIsStepsModalOpen(true)}
                    />
                  </Box>
                  <Typography variant="body" className={classes.infoText}>
                    <FormattedText
                      id={!!profileInfo?.appointment_confirmation ? 'common.enabled' : 'common.disabled'}
                    />
                  </Typography>
                </div>
              </div>
            </>
          )}
        </div>
      </SettingsBlockWrapper>

      {isStepsModalOpen && <AppointmentConfirmationStepsModal onClose={() => setIsStepsModalOpen(false)} />}
    </>
  )
}
