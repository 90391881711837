import classNames from 'classnames';
import { FC, MouseEvent, useEffect } from 'react';
// components
import { Icon } from 'src/modules/core/components/icon';
import classes from './modal.module.scss';

import type { ModalProps } from './modal.interface'
export const Modal: FC<ModalProps> = ({
  variant = 'primary',
  title,
  onClose,
  onBack,
  children,
  isCentered,
  className,
  style,
  contentClassName,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <div
      className={classNames(classes.backdrop, {
        [classes.centered]: isCentered,
      })}
      onClick={onClose}
    >
      <div
        className={classNames(classes.modal, className, {
          [classes.centered]: isCentered,
        })}
        style={style}
        onClick={(e: MouseEvent) => e.stopPropagation()}
      >
        {variant === 'primary' && onClose && (
          <div className={classNames({ [classes.header]: title })}>
            {title}
            <Icon name="close" className={classes.close} onClick={onClose} />
          </div>
        )}
        {variant === 'withBack' && (
          <div className={classNames(classes.header, classes.headerCenter)}>
            <Icon name="back" className={classes.back} onClick={onBack} />
            {title}
            <Icon name="close" className={classes.close} onClick={onClose} />
          </div>
        )}
        <div className={classNames(classes.content, contentClassName)}>{children}</div>
      </div>
    </div>
  )
}
