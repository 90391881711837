import { forwardRef, memo } from 'react';
import Select, { components, OptionProps } from 'react-select';
import StateManagedSelect from 'react-select/dist/declarations/src/stateManager';
// components
import { Checkbox, Typography } from 'src/modules/core/components';
import classes from './select-field.module.scss';

const customStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#767676ff' : '#5777DA',
    transition: 'all 0.3s',
    transform: 'scale(1.3)',
    display: 'flex',
    '&:active': {
      color: '#5777DA',
    },
    '&:hover': {
      color: '#5777DA',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    padding: '3px 0',
    color: '#959595',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #E3E3E3',
    padding: '16px 12px',
    backgroundColor: state.isFocused ? '#DEEBFF' : '#fff',
    '&:active': {
      backgroundColor: state.isFocused ? '#B2D4FF' : null,
    },
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isDisabled ? '1px solid #767676ff' : '1px solid #5777DA',
    backgroundColor: state.isDisabled ? 'rgb(239 239 239 / 30%)' : '',
    outline: state.isFocused || state.isActive ? '1px solid #5777DA' : '1px solid transparent',
    transition: 'outline 0.3s',
    boxShadow: 'none',
    borderRadius: '5px',
    padding: '10px',
    fontSize: '20px',
    '&:hover': {
      border: '1px solid #5777DA',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: '5px',
    border: '1px solid #5777DA',
    boxSizing: 'border-box',
    boxShadow: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
  }),
}

const TextOption = (props: OptionProps) => {
  return (
    <components.Option {...props}>
      <Typography variant="body" style={{ color: 'inherit' }}>
        {props.label}
      </Typography>
    </components.Option>
  )
}

const CheckboxOption = (props: OptionProps) => {
  return (
    <div>
      <components.Option {...props}>
        <Checkbox checked={props.isSelected} onChange={() => null} disabled labelDisabled={false} />
        <label className={classes.label}>
          <Typography variant="body">{props.label}</Typography>
        </label>
      </components.Option>
    </div>
  )
}

// eslint-disable-next-line react/display-name
export const SelectField: StateManagedSelect = memo(
  forwardRef(({ ...props }, ref) => {
    return (
      <Select
        isClearable={false}
        styles={customStyles}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isSearchable={false}
        ref={ref}
        components={{
          Option: props.isMulti ? CheckboxOption : TextOption,
          IndicatorSeparator: () => null,
        }}
        {...props}
      />
    )
  }),
)
