import classNames from 'classnames';
import { FC, memo, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { stripCountryCode } from '../../utils/phone-input.utils';
import classes from './phone-number-field.module.scss';

import type { PhoneNumberFieldProps } from './phone-number-field.interface'
export const PhoneNumberField: FC<PhoneNumberFieldProps> = memo(
  ({ error, inputClass, buttonClass, country = 'us', value, placeholder, enableSearch = true, ...props }) => {
    // The PhoneInput has a bug when disableCountryGuess and disableCountryCode are combined.
    // Passing a full international number (+1 and the number) infers the flag correctly
    // but treats the country code as part of the area code, truncating the number.
    // Passing a national number instead leads to incorrect country code inference.
    // Oddly, waiting for the component to load and then providing the national
    // number works as expected.
    const [currentValue, setCurrentValue] = useState<string | null>(null)
    useEffect(() => {
      setCurrentValue(stripCountryCode(value))
    }, [value])

    return (
      <PhoneInput
        inputClass={classNames(classes.input, inputClass, {
          [classes.inputError]: !!error,
        })}
        buttonClass={classNames(classes.button, buttonClass, {
          [classes.buttonError]: !!error,
          [classes.buttonDisabled]: props.disabled,
        })}
        country={country}
        enableSearch={enableSearch}
        disableSearchIcon
        disableCountryGuess
        disableCountryCode
        preferredCountries={['us']}
        searchStyle={{ fontSize: '18px' }}
        dropdownStyle={{ fontSize: '15px' }}
        value={currentValue}
        placeholder={placeholder || 'Phone number'}
        {...props}
      />
    )
  },
)

PhoneNumberField.displayName = 'PhoneNumberField'
