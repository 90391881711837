import { useQuery } from 'react-query';
import { ApiService } from 'src/services/api.service';

export const GET_EVENT_BY_URL_CODE_CACHE_KEY = 'get-event-by-url-code'

export function useEventByUrlCode(urlCode?: string) {
  return useQuery(
    [GET_EVENT_BY_URL_CODE_CACHE_KEY, urlCode],
    () => ApiService.Confirmation.getEventByUrlCode(urlCode),
    {
      retry: 0,
      enabled: Boolean(urlCode),
      staleTime: 300_000, // 5 minutes
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}
