import classNames from 'classnames';
import { useCrisp } from 'src/modules/core/hooks/use-crisp';
import { Sidebar } from '../../components/sidebar/sidebar';
import { SidebarProvider, useSidebar } from '../../context/sidebar.context';
import classes from './main-layout.module.scss';

export function MainLayout({ children }) {
  useCrisp()

  return (
    <SidebarProvider>
      <div className={classes.root}>
        <Sidebar />
        <MainLayoutContent>{children}</MainLayoutContent>
      </div>
    </SidebarProvider>
  )
}

function MainLayoutContent({ children }) {
  const { isCollapsed } = useSidebar()

  return <div className={classNames(classes.content, { [classes.contentExpanded]: isCollapsed })}>{children}</div>
}
