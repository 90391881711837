import axios, { Method } from 'axios';
import uniqid from 'uniqid';
import { axiosRefreshToken } from './axios-refresh-token';

export const allowedEventKeys = [
  'sender_name',
  'default_time_before',
  'location',
  'text',
  'receiver_numbers',
  'suggestions_reviewed',
  'active',
  'default_sender_name',
  'default_message_text',
  'default_location',
  'send_reminder_immediately',
]

export const createQueryParams = (params?: { [key: string]: any }): string => {
  if (typeof params !== 'object' || Object.keys(params).length === 0) {
    return ''
  }

  return Object.keys(params)
    .map((key, i) => {
      let prefix = '&'

      if (i === 0) {
        prefix = '?'
      }

      if (Array.isArray(params[key])) {
        const result = params[key].map((el, index) => (index === 0 ? `${key}[]=${el}` : `&${key}[]=${el}`))

        return `${prefix}${result.join('')}`
      }

      return `${prefix}${key}=${params[key]}`
    })
    .join('')
}

const authorizedApiClient = axios.create()
axiosRefreshToken(authorizedApiClient)

export const makeRequest = async <P = undefined>(url: string, method: Method, params?: P): Promise<any> => {
  const token = localStorage.getItem('token')
  const headers = {
    authorization: `Bearer ${token}`,
  }

  if (url.includes(process.env.REACT_APP_API_URL)) {
    const id = uniqid()
    headers['sentry-trace'] = id
  }

  const fetchRequest = await authorizedApiClient(url, {
    method,
    headers,
    data: params ?? undefined,
  })

  return fetchRequest.data
}

export const makePublicRequest = async <P = undefined>(url: string, method: Method, params?: P): Promise<any> => {
  const fetchRequest = await axios(url, {
    method,
    data: params ?? undefined,
  })

  return fetchRequest.data
}
