import { useContext, useEffect } from 'react';
import { LoadingWrapperContext } from 'src/modules/core/context';
import {
    DateProvider
} from 'src/modules/core/context/date-context/date-provider';
import { TabProvider } from 'src/modules/core/context/tab-context/tab-provider';
import { DashboardWrapper } from 'src/modules/user/components';

export const Dashboard = () => {
  const { disableLoading } = useContext(LoadingWrapperContext)

  useEffect(() => disableLoading(), [disableLoading])

  return (
    <DateProvider>
      <TabProvider>
        <DashboardWrapper />
      </TabProvider>
    </DateProvider>
  )
}
