import parsePhoneNumberFromString from 'libphonenumber-js';

type UserDto = {
  default_country_code: string
}

export function getDefaultCountryCode(user?: UserDto): string {
  // 1. Check localStorage
  const localStorageCode = localStorage.getItem('defaultCountryCode')
  if (localStorageCode && localStorageCode !== 'null' && localStorageCode.length === 2) {
    return localStorageCode.toLowerCase()
  }

  // 2. Check the user's default_country_code
  if (user?.default_country_code) {
    return user.default_country_code.toLowerCase()
  }

  // 3. Infer from the browser's language
  const browserLanguage = navigator.language || navigator.languages?.[0]
  if (browserLanguage && browserLanguage.includes('-')) {
    const inferredCountry = browserLanguage.split('-')[1]
    return inferredCountry.toLowerCase()
  }

  // 4. Fallback to 'us'
  return 'us'
}

export function getPhoneAndCountryCode(
  phoneNumber: string | null | undefined,
  defaultCountry: string,
): { phone: string; country: string } {
  if (phoneNumber) {
    const normalized = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`
    const parsed = parsePhoneNumberFromString(normalized)
    if (parsed?.isValid()) {
      return { phone: parsed.number, country: parsed.country.toLowerCase() }
    }
  }
  return { phone: (phoneNumber ?? '').replace(/\D/g, ''), country: defaultCountry }
}

export function stripCountryCode(phoneNumber: string | null | undefined): string {
  if (!phoneNumber) return ''

  const normalized = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`
  const parsed = parsePhoneNumberFromString(normalized)
  if (parsed?.isValid()) {
    return parsed.nationalNumber
  }

  return phoneNumber.replace(/^\+/, '')
}

export function getPlaceholder(country: string | undefined | null) {
  const placeholders = {
    us: '(555) 123-1234',
    fr: '06 12 34 56 78',
    ca: '(555) 123-1234',
    au: '(04) 1234 5678',
    gb: '07123 456789',
    es: '612 345 678',
    de: '0151 23456789',
    mx: '55 123 4567',
    it: '347 1234567',
    br: '(99) 988887777',
  }
  return placeholders[country?.toLowerCase()] || 'Phone number'
}
