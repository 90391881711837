import parsePhoneNumberFromString, {
    parsePhoneNumber
} from 'libphonenumber-js';
import { ChangeEvent, FC, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { CountryData } from 'react-phone-input-2';
import generateRandomId from 'src/helpers/generate-random-id';
import {
    Button, FormattedText, FormControl, Icon, PhoneNumberField, SelectField,
    TextField, ToggleSwitch, Typography
} from 'src/modules/core/components';
import {
    MessageInput
} from 'src/modules/core/components/message-input/message-input';
import {
    getDefaultCountryCode, getPhoneAndCountryCode
} from 'src/modules/core/utils/phone-input.utils';
import {
    maxMessageLength, maxMessageLengthWithConfirmation, onReminderMessageInput
} from 'src/modules/event/constants/event.constants';
import { generateEstimatedEventMessage } from 'src/modules/event/utils/helpers';
import { timingOptions } from 'src/modules/user/constants/profile.constants';
import { settingsFormSchema } from 'src/modules/user/constants/settings-form';
import {
    useOptimisticUpdateProfile
} from 'src/modules/user/hooks/use-optimistic-update-profile';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import classes from './settings-profile-info-form.module.scss';

import type { ProfileFormData } from 'src/modules/user/types/profile.types'
import type { SettingsProfileInfoFormProps } from './settings-profile-info-form.interface'
const MAX_PHONE_NUMBERS = 10

export const SettingsProfileInfoFormPersonalMode: FC<SettingsProfileInfoFormProps> = ({
  profileInfo,
  setIsProfileInfoEditing,
}) => {
  const optimisticUpdateProfile = useOptimisticUpdateProfile()
  const { formatMessage } = useIntl()

  const defaultCountryCode = getDefaultCountryCode(profileInfo)
  const existingPhoneNumbers = (profileInfo.phone_numbers?.map((n) => n?.toString() || '') ?? []).filter(Boolean)
  if (existingPhoneNumbers.length === 0) {
    existingPhoneNumbers.push('')
  }

  const {
    control,
    register,
    handleSubmit,
    watch,
    setError,
    formState: { isDirty, errors, dirtyFields },
  } = useForm<ProfileFormData>({
    defaultValues: {
      from: profileInfo.sender_name,
      phoneNumbers: existingPhoneNumbers.map((phone) => ({
        id: generateRandomId(),
        ...getPhoneAndCountryCode(phone, defaultCountryCode),
      })),
      meetingLocation: profileInfo.default_location,
      defaultReminder: profileInfo.default_message_text,
      timing: (profileInfo.default_time_before ?? []).map((t) => t.toString()),
      personalRemindersForAllAppointments: profileInfo.personal_reminders_for_all_appointments,
    },
    // @ts-ignore
    resolver: yupResolver(settingsFormSchema),
  })

  const {
    fields: phoneNumbers,
    append: addPhoneNumber,
    remove: removePhoneNumber,
  } = useFieldArray({
    control,
    name: 'phoneNumbers',
  })

  function handleAddPhoneNumber() {
    if (phoneNumbers.length < MAX_PHONE_NUMBERS) {
      addPhoneNumber({
        id: generateRandomId(),
        country: defaultCountryCode,
        phone: '',
      })
    }
  }

  const { defaultReminder, meetingLocation, from } = watch()

  const eventMessage = useMemo(
    () =>
      generateEstimatedEventMessage({
        ...profileInfo,
        appointment_confirmation: false,
        sender_name: from,
        default_location: meetingLocation,
        default_message_text: defaultReminder,
      }),
    [defaultReminder, from, meetingLocation, profileInfo],
  )

  const onSubmit = (data: ProfileFormData) => {
    if (isDirty && Object.keys(dirtyFields).length !== 0) {
      if (profileInfo.appointment_confirmation && eventMessage.length > maxMessageLengthWithConfirmation) {
        return setError('defaultReminder', {
          type: 'custom',
          message: 'Default SMS Reminder must be shorter than 239 characters',
        })
      }

      if (eventMessage.length > maxMessageLength) {
        return setError('defaultReminder', {
          type: 'custom',
          message: 'Default SMS Reminder must be shorter than 320 characters',
        })
      }

      const phoneNumbers = new Set<string>()
      for (const [index, phone] of data.phoneNumbers.entries()) {
        const phoneNumber = `+${phone.phone.replace(/\D/g, '')}`
        const parsed = parsePhoneNumberFromString(phoneNumber)
        if (!parsed?.isValid()) {
          return setError(`phoneNumbers.${index}.phone`, {
            type: 'custom',
            message: 'Invalid phone number',
          })
        }
        phoneNumbers.add(phoneNumber)
      }

      optimisticUpdateProfile.mutate({
        sender_name: data.from,
        phone_numbers: Array.from(phoneNumbers),
        default_location: data.meetingLocation,
        default_message_text: data.defaultReminder,
        default_time_before: data.timing.map((time) => +time),
        personal_reminders_for_all_appointments: data.personalRemindersForAllAppointments,
      })
    }

    setIsProfileInfoEditing(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          wrapperClassName={classes.textFieldWrapper}
          className={classes.textField}
          labelClassName={classes.label}
          label={formatMessage({ id: 'configuration.step1.label.from' })}
          placeholder={formatMessage({
            id: 'configuration.step1.personalMode.from.placeholder',
          })}
          {...register('from')}
          error={errors.from?.message}
        />
        <Box marginBottom={3}>
          {phoneNumbers.map((phone, index) => (
            <Box marginBottom={1} key={phone.id}>
              <FormControl
                labelClassName={classes.label}
                label={`${formatMessage({
                  id: 'configuration.step1.personalMode.phone.label',
                })} ${index + 1}`}
                error={errors.phoneNumbers && errors.phoneNumbers[index]?.phone.message}
              >
                <Controller
                  control={control}
                  name={`phoneNumbers.${index}`}
                  defaultValue={phone}
                  render={(props) => (
                    <Box display="flex" alignItems="center" gridColumnGap={10}>
                      <PhoneNumberField
                        value={phone.phone}
                        country={phone.country}
                        inputClass={classes.phoneNumberInput}
                        error={errors.phoneNumbers && errors.phoneNumbers[index]?.phone.message}
                        onChange={(value, country: CountryData) => {
                          props.field.onChange({
                            country: country.countryCode,
                            phone: `+${country.dialCode.replace(/\D/g, '')}${value.replace(/\D/g, '')}`,
                          })
                        }}
                      />
                      {index > 0 && (
                        <span onClick={() => removePhoneNumber(index)}>
                          <Icon name="close" className={classes.removeButton} />
                        </span>
                      )}
                    </Box>
                  )}
                />
              </FormControl>
            </Box>
          ))}
          {phoneNumbers.length < MAX_PHONE_NUMBERS && (
            <Typography variant="body" className={classes.textButton} onClick={handleAddPhoneNumber}>
              + <FormattedText id="configuration.step1.personalMode.addPhone" />
            </Typography>
          )}
        </Box>
        <TextField
          wrapperClassName={classes.textFieldWrapper}
          className={classes.textField}
          labelClassName={classes.label}
          label={formatMessage({
            id: 'configuration.step1.personalMode.location.label',
          })}
          placeholder={formatMessage({
            id: 'configuration.step1.placeholder.location',
          })}
          Element="textarea"
          {...register('meetingLocation')}
          error={errors.meetingLocation?.message}
        />
        <MessageInput
          wrapperClassName={classes.textFieldWrapper}
          labelClassName={classes.label}
          label={formatMessage({
            id: 'configuration.step1.personalMode.reminder.label',
          })}
          placeholder={formatMessage({
            id: 'configuration.step1.personalMode.reminder.placeholder',
          })}
          rows={3}
          {...register('defaultReminder')}
          error={errors.defaultReminder?.message}
          showDynamicDataHelper
          confirmationsEnabled={profileInfo.appointment_confirmation}
          messagePreview={eventMessage}
        />
        <FormControl
          label={formatMessage({
            id: 'configuration.step1.personalMode.timing.label',
          })}
          labelClassName={classes.label}
          error={errors?.timing?.message}
        >
          <Controller
            control={control}
            defaultValue={[]}
            name="timing"
            render={({ field: { onChange, value, ref } }) => (
              <SelectField
                ref={ref}
                value={timingOptions.filter((c) => value.includes(c.value))}
                onChange={(val) => onChange(val.map((c) => c.value))}
                className={classes.textFieldWrapper}
                options={timingOptions}
                isMulti
              />
            )}
          />
        </FormControl>
        <FormControl
          label={formatMessage({
            id: 'settings.personalMode.autoReminders.label',
          })}
          labelClassName={classes.label}
        >
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            <Controller
              control={control}
              name="personalRemindersForAllAppointments"
              render={({ field: { onChange, value, ref } }) => (
                <ToggleSwitch
                  ref={ref}
                  checked={value}
                  labelClassName={classes.switchLabel}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                />
              )}
            />
            <Typography variant="body" className={classes.infoText}>
              <FormattedText id={watch('personalRemindersForAllAppointments') ? 'common.enabled' : 'common.disabled'} />
            </Typography>
          </Box>
        </FormControl>
        <Button
          type="submit"
          className={classes.button}
          widthType="content"
          isLoading={optimisticUpdateProfile.isLoading}
        >
          <FormattedText id="common.save" />
        </Button>
      </form>
    </>
  )
}
