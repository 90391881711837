import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserDto } from 'src/helpers/user.dto';
import { FormattedText } from 'src/modules/core/components';
import { Icon } from 'src/modules/core/components/icon/icon';
import { routeNames } from 'src/modules/core/constants/routeNames';
import { SubscriptionStatus } from 'src/modules/payment/types/payment.types';
import { BillingPlanDto, BillingSubscriptionDto } from '@grouse-software/types';
import { Typography } from '../../../../core/components/typography/typography';
import styles from './account-snapshot.module.scss';
import { MetricCard } from './metric-card';

interface AccountSnapshotProps {
  profile?: UserDto
  subscription?: BillingSubscriptionDto
  currentPlan?: BillingPlanDto
  onUpgradeClick?: () => void
}

function getDaysRemaining(profile?: UserDto) {
  // If theres a value in profile?.billing_cycle (e.g., 18), return how many days from today to that day.
  // Account for when the next month doesn't have that day, e.g., billing_cycle is 31 but the month has 30, 29 or 28 days
  if (profile?.billing_cycle_day) {
    const today = new Date()
    const resetMonth = today.getDate() < profile.billing_cycle_day ? today.getMonth() : today.getMonth() + 1
    const resetYear = resetMonth === 12 ? today.getFullYear() + 1 : today.getFullYear()
    const nextResetDate = new Date(resetYear, resetMonth % 12, profile.billing_cycle_day)
    const daysUntilNextReset = Math.ceil((nextResetDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))
    console.log('daysUntilNextReset', daysUntilNextReset)
    return daysUntilNextReset
  }

  // If we reached this point, return 0
  return 0
}

function getError(
  profile?: UserDto,
  subscription?: BillingSubscriptionDto,
  currentPlan?: BillingPlanDto,
): React.ReactNode | null {
  const limitReached = profile.reminders_sent_during_billing_cycle === currentPlan?.reminders_limit
  const overLimit =
    profile.reminders_sent_during_billing_cycle + profile.include.count_scheduled_reminders >
    currentPlan?.reminders_limit

  if (subscription?.state === SubscriptionStatus.past_due) {
    return (
      <FormattedText
        id="payment.error.status"
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
          nextBillDate: subscription?.next_payment?.date,
        }}
      />
    )
  }

  if (limitReached) {
    return <FormattedText id="payment.error.limitReached" values={{ strong: (chunks) => <strong>{chunks}</strong> }} />
  }

  if (overLimit) {
    return <FormattedText id="payment.error.overLimit" values={{ strong: (chunks) => <strong>{chunks}</strong> }} />
  }

  return null
}

export function AccountSnapshot({ profile, subscription, currentPlan }: AccountSnapshotProps) {
  const navigate = useNavigate()

  const planType = currentPlan?.name || 'Free Trial'
  const remindersCount = profile?.reminders_sent_during_billing_cycle || 0
  const scheduledReminders = profile?.scheduled_reminders_count || 0
  const daysRemaining = getDaysRemaining(profile)
  const remainingReminders = currentPlan?.reminders_limit - remindersCount
  const error = getError(profile, subscription, currentPlan)

  const handleChangeSubscription = useCallback(() => {
    navigate(`${routeNames.settings.path}?tab=2`)
  }, [navigate])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleSection}>
          <Typography variant="h2" className={styles.title}>
            {planType}
          </Typography>
          <button className={styles.upgradeButton} onClick={handleChangeSubscription}>
            <Icon name="arrowLeft" className={styles.collapseIcon} />
            Upgrade
          </button>
        </div>

        <div className={styles.metricsContainer}>
          <MetricCard value={remindersCount} label="Reminders sent this billing cycle" icon="bell" />
          <MetricCard value={scheduledReminders} label="Reminders scheduled" icon="bell-calendar" />
          <MetricCard value={remainingReminders} label="Reminders remaining" icon="bell-dotted" />
          {daysRemaining !== 0 ? (
            <MetricCard value={daysRemaining} label="Days remaining this billing cycle" icon="calendar" />
          ) : null}
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          <span className={styles.errorIcon}>⚠</span>
          {error}
        </div>
      )}
    </>
  )
}
