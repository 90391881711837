import { FC, useContext } from 'react';
import { SelectField } from 'src/modules/core/components';
import { TabContext } from 'src/modules/core/context/tab-context/tab-context';
import { dashboardTabs } from 'src/modules/user/constants/dashboardTabs';

export function TabSelect() {
  const { currentTabIndex, setCurrentTabIndex, setPrevTabIndex } = useContext(TabContext)

  // Convert dashboard tabs to select options format
  const tabOptions = dashboardTabs.map((tab, index) => ({
    value: index,
    label: tab.title,
    description: tab.description,
  }))

  // Get current selected option
  const selectedOption = tabOptions[currentTabIndex]

  const handleChange = (option: any) => {
    setPrevTabIndex(currentTabIndex)
    setCurrentTabIndex(option.value)
  }

  return (
    <SelectField
      value={selectedOption}
      onChange={handleChange}
      closeMenuOnSelect={true}
      options={tabOptions}
      isMulti={false}
      styles={{ control: (base) => ({ ...base, padding: '2px 10px 2px 15px' }) }}
    />
  )
}
