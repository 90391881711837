import { FC, PropsWithChildren, useState } from 'react';
import { TabContext } from './tab-context';

export const TabProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [prevTabIndex, setPrevTabIndex] = useState(0)

  return (
    <TabContext.Provider
      value={{
        currentTabIndex,
        setCurrentTabIndex,
        prevTabIndex,
        setPrevTabIndex,
      }}
    >
      {children}
    </TabContext.Provider>
  )
}
