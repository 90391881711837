import classNames from 'classnames';
import { EventPreviewWithReminderData } from '../../types/event.types';
import {
    EventSuggestedNumbersHelper
} from '../../utils/EventSuggestedNumbersHelper';
import classes from './event-card-status.module.scss';

export type EventCardStatusProps = {
  event: EventPreviewWithReminderData
  isError?: boolean
  /**
   * In some cases, the Event Card is used to display reminders,
   * and in that case the event_date is already the reminder date.
   */
  eventDateIsReminderDate?: boolean
}

type EventStatus = 'error' | 'inactive' | 'review' | 'scheduled' | 'sent'

export function EventCardStatus({ event, isError, eventDateIsReminderDate }: EventCardStatusProps) {
  const calculateReminderTiming = () => {
    const eventDate = new Date(Number(event.event_date)).getTime()

    const getReminderOffset = () => {
      if (!event.reminders?.length) return 0
      return Math.min(...event.reminders.map((reminder) => reminder.time_before))
    }

    const reminderDate = eventDateIsReminderDate ? eventDate : eventDate - getReminderOffset()

    const currentTime = new Date().getTime()
    const isPastDate = currentTime > reminderDate

    return { reminderDate, isPastDate }
  }

  let status: EventStatus = 'inactive'
  const { isPastDate } = calculateReminderTiming()

  if (event.active && event.reminders?.length) {
    status = isPastDate ? 'sent' : 'scheduled'
  }

  if (!isPastDate && EventSuggestedNumbersHelper.needsReview(event)) {
    status = 'review'
  }

  if (isError || event.errors.length > 0) {
    status = 'error'
  }

  const cssClass = classNames(classes.status, {
    [classes.statusError]: status === 'error',
    [classes.statusInactive]: status === 'inactive',
    [classes.statusReview]: status === 'review',
    [classes.statusScheduled]: status === 'scheduled',
    [classes.statusSent]: status === 'sent',
  })

  return <span className={classNames(classes.status, cssClass)}>{status}</span>
}
