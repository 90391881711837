import { Typography } from '../../../../core/components/typography/typography';
import { ReactComponent as BellCalendar } from './icons/icon-bell-calendar.svg';
import { ReactComponent as BellDotted } from './icons/icon-bell-dotted.svg';
import { ReactComponent as BellIcon } from './icons/icon-bell.svg';
import { ReactComponent as Calendar } from './icons/icon-calendar.svg';
import styles from './metric-card.module.scss';

interface MetricCardProps {
  value: number
  label: string
  icon: 'bell' | 'bell-calendar' | 'bell-dotted' | 'calendar'
}

export function MetricCard({ value, label, icon }: MetricCardProps) {
  const iconMap = {
    bell: BellIcon,
    'bell-calendar': BellCalendar,
    'bell-dotted': BellDotted,
    calendar: Calendar,
  }
  const IconComponent = iconMap[icon]

  return (
    <div className={styles.card}>
      <span className={styles.icon}>
        <IconComponent />
      </span>
      <div className={styles.content}>
        <Typography variant="body" className={styles.label}>
          {label}
        </Typography>
        <Typography variant="h3" className={styles.value}>
          {value}
        </Typography>
      </div>
    </div>
  )
}
